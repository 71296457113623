import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Loading from '~/components/Loading'
import { useTranslation, withTranslation } from '~/i18n'
import { saveAccountAction } from '~/redux/actions/User'
import { getClientRegisterServicesAPI } from '~/redux/apis'
import { DTRACK, DTRACK_HOSTS, VIETFISH_HOSTS } from '~/utils/constants'
function Index({ accessToken, userRoles, userLocale, saveAccount, platform }) {
  const router = useRouter()
  const { i18n } = useTranslation()

  const redirectUser = async () => {
    try {
      const result = await getClientRegisterServicesAPI()
      if (result?.data?.urss?.length > 0) {
        router.replace('/dashboard')
      } else {
        router.replace('/services')
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  useEffect(() => {
    if (accessToken && userRoles) {
      !userLocale && saveAccount({ locale: i18n.language })

      if (userRoles?.length > 0) {
        return router.replace('/admin/producers')
      } else {
        if (platform === DTRACK) {
          return redirectUser()
        } else {
          return router.replace('/dashboard')
        }
      }
    } else {
      return router.replace('/services')
    }
  }, [accessToken, userRoles])

  return <Loading />
}

export async function getServerSideProps(context) {
  const host = context?.req?.headers?.host
  const props = {}

  if (DTRACK_HOSTS.includes(host)) {
    props.metaTags = {
      title: 'DTRACK ASIA',
      desc: 'Nền tảng cung cấp dịch vụ Nông nghiệp hàng đầu Việt Nam.',
      image: '/assets/png/seo.png',
    }
  } else if (VIETFISH_HOSTS.includes(host)) {
    props.metaTags = {
      title: 'Vietfish Expo',
      desc: 'VietFish là hội chợ giúp gian hàng của bạn lên sàn thương mại điện tử nhanh chóng và chuyên nghiệp',
      image: '/assets/jpeg/vietfish_logo.jpeg',
    }
  }

  return {
    props,
  }
}

const mapStateToProps = (state) => ({
  accessToken: state.user?.token?.access_token,
  userRoles: state.user?.roles,
  userLocale: state.user?.locale,
  platform: state.layout?.platform?.platform,
})

const mapDispatchToProps = (dispatch) => ({
  saveAccount: (payload) => dispatch(saveAccountAction(payload)),
})

export default compose(withTranslation(null), connect(mapStateToProps, mapDispatchToProps))(Index)
